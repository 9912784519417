import {
  createPost,
  deletePost,
  FetchType,
  getPost,
  getPosts,
  putVote,
  reportPost,
} from "../api/post";
import {
  UiIcon,
  Utils,
  generateUlid,
  getTimestampFromUlid,
  markdownToHTMLElement,
  random,
  ulidFromString,
  ulidStringify,
} from "../bin/utils";
import {
  postType as POSTTYPE,
  FullPost,
  PostBodies,
  PostBody,
  Vote,
  CreatePostDetails,
} from "../protos/post";
import { RouteBuilder, routeNavigator } from "../routes/navigator";
import van, { State } from "vanjs-core";
import { Await } from "vanjs-ui";
import { dropDownMenu } from "./dropdown";
import { AuthHandler } from "../firebase/auth";
import { LupydMarkdownElements } from "./pMarkdown";
import * as vanX from "vanjs-ext";
import { UserProfileElement } from "../routes/userPage";
import { PickedFileUrl } from "./create-post";

const { li, input, ul, a, div, span, p, button } = van.tags;

export const PostPage = (post: FullPost) => {
  return div({ class: "center" }, postElement(post, false, true, false));
};

export const postPageBuilder: RouteBuilder = async (url: string, data: any) => {
  const parsedUrl = new URL(url);
  const prefix = "/post/";
  if (!parsedUrl.pathname.startsWith(prefix)) {
    return undefined;
  }
  const id = parsedUrl.pathname.slice(prefix.length);

  if (data && "post" in data) {
    return PostPage(FullPost.create(data.post));
  }

  if (id.length != 0) {
    const postId = ulidFromString(id);
    const post = await getPost(ulidStringify(postId));
    if (post) {
      return PostPage(post);
    }
  }
};

export const postElement = (
  post: FullPost,
  shouldNavigate = false,
  disableEffects = false,
  isInReplies = false,
) => {
  const rootParent = div({
    class: isInReplies ? "reply-div-wrapper" : "post-div-wrapper",
  });
  const id = ulidStringify(post.id!);

  const href = `/post/${id}`;
  const el = div(
    { class: "post-div" },
    shouldNavigate
      ? a(
          {
            clasS: "no-text-decoration",
            href,
            onclick: (e: Event) => {
              e.preventDefault();
              e.stopPropagation();
              routeNavigator.pushPageAndState(href, PostPage(post));
            },
          },
          postHeader(post),
        )
      : postHeader(post),
    new PostBodyElement(post, disableEffects, isInReplies),
    postFooter(post, rootParent),
  );

  el.setAttribute("data-post-id", id);

  rootParent.replaceChildren(el);

  return rootParent;
};

export class PostBodyElement extends HTMLElement {
  postTitle: string;
  effect?: string;
  post: FullPost;

  disableEffects: boolean;
  isInReplies: boolean;

  constructor(
    post: FullPost,
    disableEffects: boolean = false,
    isInReplies: boolean = false,
  ) {
    super();
    this.post = post;
    this.disableEffects = disableEffects;
    this.postTitle = post.title;
    this.isInReplies = isInReplies;
  }

  connectedCallback() {
    this.render();
  }

  render() {
    const bodies = PostBodies.decode(this.post.body);

    const titleSpan =
      this.post.title.length > 0
        ? this.isInReplies
          ? span(this.post.title)
          : span({ class: "post-title" }, this.post.title)
        : span();
    const repliedToSpan = (() => {
      if (this.post.replyingTo.byteLength == 16 && !this.isInReplies) {
        const id = ulidStringify(this.post.replyingTo);
        return span(
          { class: "p8" },
          "Replied to Post ",
          a(
            {
              class: "theme-anchor",
              href: `/post/${id}`,
            },
            `#${id}`,
          ),
        );
      }

      return span();
    })();
    const editsCount = bodies.bodies.length;
    const editIndex = van.state(editsCount - 1);
    let bodyEl: HTMLElement;
    const body = bodies.bodies[editIndex.val];
    if (editsCount == 0) {
      bodyEl = div();
    } else if (editsCount == 1) {
      bodyEl = postBodyElement(body);
    } else {
      bodyEl = div(
        div(
          { class: "edit-row" },
          () => span(`Edited ${editsCount} times`),
          button(
            {
              class: "theme-button",
              title: "show previous edit",
              onclick: () => (editIndex.val = Math.max(editIndex.val - 1, 0)),
            },
            UiIcon("chevron-left"),
          ),
          () => span(`${editIndex.val + 1}`),
          button(
            {
              class: "theme-button",
              title: "show next edit",
              onclick: () =>
                (editIndex.val = Math.min(editIndex.val + 1, editsCount - 1)),
            },
            UiIcon("chevron-right"),
          ),
        ),
        () => postBodyElement(bodies.bodies[editIndex.val]),
      );
    }
    bodyEl =
      this.post.postType !== POSTTYPE.SAFE ? blurElement(true, bodyEl) : bodyEl;
    this.replaceChildren(repliedToSpan, titleSpan, bodyEl);
  }
}

export const postBodyElement = (
  body: PostBody,
  pickedFileUrls: PickedFileUrl[] = [],
) => {
  let bodyEl: HTMLElement = div();
  try {
    if (body.elements) {
      bodyEl = new LupydMarkdownElements(body.elements);
    } else if (body.markdown) {
      bodyEl = markdownToHTMLElement(body.markdown, pickedFileUrls);
    } else if (body.plainText) {
      bodyEl = p(body.plainText);
    } else {
      console.error("Unsupported Post Body Format");
    }
  } catch (err) {
    console.error(`Failed to parse post body `, err);
    bodyEl = p("Parsing Post Body went wrong");
  }

  return div({ class: "lupyd-post-body" }, bodyEl);
};

// export class BlurElement extends HTMLElement {
//   constructor(...children: HTMLElement[]) {
//     super();
//     this.append(...children);
//     this.toggleAttribute("blur-on", true);
//     this.addEventListener("click", (_) => {
//       this.toggleAttribute("blur-on");
//     });
//   }
// }

const blurElement = (enable: boolean = true, ...children: HTMLElement[]) => {
  const blurState = van.state(enable);
  return div(
    {
      class: "stack",
      onclick() {
        blurState.val = !blurState.val;
      },
    },
    () =>
      blurState.val
        ? div(
            {
              class: "content-warning",
            },
            div({ class: "center" }, "Content may be harmful"),
          )
        : div(),
    div(
      {
        class: () => (blurState.val ? "blur-on" : undefined),
      },
      ...children,
    ),
  );
};

const postHeader = (post: FullPost) => {
  const elements: HTMLElement[] = [];

  {
    elements.push(UserProfileElement(post.by));
  }
  {
    const isAnonymous =
      (post.postType & POSTTYPE.ANONYMOUS) == POSTTYPE.ANONYMOUS;
    const username = post.by;
    const usernameSpan =
      username.length === 0 || isAnonymous
        ? a(
            {
              class: "post-username",
              href: `#`,
              onclick(e: Event) {
                e.preventDefault();
              },
            },
            "Anonymous",
          )
        : a({ class: "post-username", href: `/user/${username}` }, username);

    usernameSpan.addEventListener("click", (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (!isAnonymous) routeNavigator.pushRoute(usernameSpan.href);
    });

    const ts = getTimestampFromUlid(post.id!);
    const timestampSpan = span(new Date(ts).toLocaleString());

    const addresser = div(
      { class: "post-header-addresser" },
      ...[usernameSpan, timestampSpan],
    );

    // const children: HTMLElement[] = [addresser];

    const flairElements = div(
      { class: "row" },

      (POSTTYPE.NSFW & post.postType) === POSTTYPE.NSFW
        ? span({ class: `post-flair nsfw` }, "NSFW")
        : undefined,
      (POSTTYPE.NSFW & post.postType) === POSTTYPE.NSFW
        ? span({ class: `post-flair nsfl` }, "Dangerous")
        : undefined,
    );

    // if ((POSTTYPE.NSFW & post.postType) === POSTTYPE.NSFW) {
    //   children.push(span({ class: `post-flair nsfw` }, "NSFW"));
    // }
    // if ((POSTTYPE.DANGEROUS & post.postType) === POSTTYPE.DANGEROUS) {
    //   children.push(span({ class: `post-flair nsfl` }, "Dangerous"));
    // }

    const info = div({ class: "post-header-info" }, addresser, flairElements);

    elements.push(info);
  }

  return div({ class: "post-header" }, ...elements);
};

const postVotes = (post: FullPost, initalUserVote: boolean | null = null) => {
  const upvoteCount = van.state(post.upvotes);
  const downvoteCount = van.state(post.downvotes);
  const userVote = van.state<boolean | null>(initalUserVote);
  const onClickEventListener = (voteSign: boolean) => {
    return async (event: Event) => {
      event.preventDefault();
      event.stopPropagation();

      if (null == (await AuthHandler.getUsername())) {
        Utils.showSnackBar("You must be authenticated to vote");
        return;
      }
      if (userVote.val === null) {
        userVote.val = voteSign;
        if (voteSign) {
          upvoteCount.val += 1n;
        } else {
          downvoteCount.val += 1n;
        }
        putVote(Vote.create({ id: post.id, val: { val: voteSign } }));
      } else {
        if (userVote.val == voteSign) {
          userVote.val = null;
          if (voteSign) {
            upvoteCount.val -= 1n;
          } else {
            downvoteCount.val -= 1n;
          }
          putVote(Vote.create({ id: post.id, val: undefined }));
        } else {
          userVote.val = voteSign;
          if (voteSign) {
            upvoteCount.val += 1n;
            downvoteCount.val -= 1n;
          } else {
            upvoteCount.val -= 1n;
            downvoteCount.val += 1n;
          }
          putVote(Vote.create({ id: post.id, val: { val: voteSign } }));
        }
      }
    };
  };

  // use colored class for red and blue votes
  return div(
    { class: "lupyd-post-vote" },
    button(
      {
        onclick: onClickEventListener(true),
        class: "post-vote up theme-button no-button-decoration",
      },

      () =>
        userVote.val === true
          ? UiIcon("thumbs-up-filled")
          : UiIcon("thumbs-up"),
    ),
    span(
      () =>
        `${Utils.formatNumber(Number(upvoteCount.val))} | ${Utils.formatNumber(Number(downvoteCount.val))}`,
    ),
    button(
      {
        onclick: onClickEventListener(false),
        class: "post-vote down theme-button no-button-decoration",
      },
      () =>
        userVote.val === false
          ? UiIcon("thumbs-down-filled")
          : UiIcon("thumbs-down"),
    ),
  );
};

// customElements.define("lupyd-post", PostElement);
customElements.define("lupyd-post-body", PostBodyElement);
// customElements.define("blur-element", BlurElement);

// export function postTypeFromInt(
//   flairInt: number,
// ): "Anonymous" | "NSFW" | "Dangerous" | undefined {
//   switch (flairInt) {
//     case 1: {
//       return "Anonymous";
//     }
//     case 2: {
//       return "NSFW";
//     }
//     case 3: {
//       return "Dangerous";
//     }
//   }
// }

// export function postTypeFlairEl(flairName: string) {
//   let className = "default";
//   switch (flairName) {
//     case "NSFW":
//     case "Spoiler":
//     case "Anonymous":
//       className = flairName;
//       break;
//     case "Dangerous":
//     case "NSFL":
//       className = "NSFL";
//       break;
//   }

//   return span({ class: `post-flair ${className}` }, flairName);
// }

//// Tests /////
const videoUrls = [
  "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
  "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
  "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4",
  "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4",
  "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4",
];

const randomPostBody = () => {
  if (random.nextBool()) {
    return random.nextString(512);
  } else {
    let url = "[youtube](https://www.youtube.com)";
    if (random.nextBool()) {
      if (random.nextBool()) {
        url = `[video](${random.nextElement(videoUrls)})`;
      } else {
        url = `[image](https://picsum.photos/${random.nextInt(400, 1000)}/${random.nextInt(400, 1000)})`;
      }
    }
    return JSON.stringify({
      effect: random.nextBool() ? undefined : "blur",
      body: random.nextString(512) + url + random.nextString(512),
    });
  }
};

export const randomPost = () => {
  const title = random.nextBool(0.3) ? "" : random.nextString(128);
  const body = new TextEncoder().encode(randomPostBody());
  const by = random.nextString(15);
  const postType = random.nextInt(0, 3);
  const id = generateUlid();
  return FullPost.create({
    id,
    title,
    by,
    postType,
    body,
  });
};

export const testPosts = (length: number) =>
  Array.from({ length }, (_, __) => randomPost());

const postMoreButton = (
  post: FullPost,
  postElement: HTMLElement | undefined,
) => {
  const shareHref = `/post/${ulidStringify(post.id)}`;
  const editPostHref = `/create-post`;

  const reportText = van.state("");

  const showReportDialog = () => {
    Utils.showDialog(
      div(
        { class: "report-dialog" },
        div(
          "Give us some more information about why this post should be reported ? (Optional)",
        ),
        input({
          class: "report-text-input theme-input",
          type: "text",
          oninput() {
            reportText.val = (this as HTMLInputElement).value;
          },
        }),
      ),
      [
        {
          class: "theme-button ",
          text: "Confirm",
          onClick: () => {
            reportPost(post.id, reportText.val);
          },
        },
        {
          class: "theme-button ",
          text: "Cancel",
          onClick: () => {},
        },
      ],
      () => {},
    );
  };

  const defaultTiles = [
    button(
      {
        class: "theme-button ",
        // href: `#share?url=${window.location.origin}${shareHref}`,
        onclick: (e: Event) => {
          e.preventDefault();
          Utils.shareUrl(shareHref);
        },
      },
      "Share",
    ),
    button(
      {
        class: "theme-button ",
        // href: `#report?id=${ulidStringify(post.id)}`,
        onclick: (e: Event) => {
          e.preventDefault();
          showReportDialog();
        },
      },
      "Report",
    ),
  ];

  const defaultMoreOptions = () =>
    dropDownMenu(UiIcon("ellipsis-vertical"), defaultTiles);

  const userState = AuthHandler.currentUser();
  return div(
    { class: "center", style: "height:auto" },
    div(() => {
      const user = userState.val;

      return Await(
        { value: AuthHandler.getUsername(user), Loading: defaultMoreOptions },
        (username) =>
          username != null && username == post.by
            ? dropDownMenu(UiIcon("ellipsis-vertical"), [
                button(
                  {
                    class: "theme-button ",
                    onclick: (e: Event) => {
                      e.preventDefault();
                      routeNavigator.pushRoute(editPostHref, {
                        post,
                        edit: true,
                      });
                    },
                  },
                  "Edit",
                ),
                button(
                  {
                    class: "theme-button ",
                    onclick: (_: Event) => {
                      Utils.showDialog(
                        div("Confirm Deleting Post?"),
                        [
                          {
                            text: "Yes",
                            onClick: () => {
                              deletePost(post.id);
                              postElement?.replaceWith(div());
                            },
                            class: "theme-button",
                          },
                          {
                            text: "No",
                            onClick: () => {},
                            class: "theme-button",
                          },
                        ],
                        () => {},
                      );
                    },
                  },
                  "Delete",
                ),
                ...defaultTiles,
              ])
            : defaultMoreOptions(),
      );
    }),
  );
};

const postFooter = (post: FullPost, el: HTMLElement) => {
  const showReplies = van.state(false);

  let repliesElement: undefined | HTMLElement = undefined;
  const repliesCount = van.state(Number(post.replies));

  return div(
    div(
      { class: "post-footer" },
      postVotes(post, post.vote?.val),
      button(
        {
          class: "theme-button no-button-decoration replies-count-btn",
          onclick: () => (showReplies.val = !showReplies.val),
        },
        span(repliesCount),
        UiIcon("reply"),
      ),
      postMoreButton(post, el),
    ),
    () => {
      if (showReplies.val) {
        if (!repliesElement) {
          repliesElement = postReplies(post, repliesCount);
        }
        return repliesElement!;
      } else {
        return div();
      }
    },
  );
};
function postReplies(post: FullPost, repliesCount: State<number>) {
  const replies = vanX.reactive([] as FullPost[]);
  let start: string | undefined = undefined;

  const loadMore = async () => {
    getPosts({
      fetchType: FetchType.Replies,
      start,
      fetchTypeFields: ulidStringify(post.id!),
    })
      .then((posts) => {
        if (posts.length === 0) return;
        let minId = posts[0].id;
        for (const post of posts) {
          if (post.id < minId) {
            minId = post.id;
          }
          replies.push(vanX.noreactive(post));
        }

        start = ulidStringify(minId);
      })
      .catch(console.error);
  };

  if (repliesCount.val > 0) {
    loadMore();
  }

  const inputText = van.state("");

  let isUploading = false;
  const uploadReply = async () => {
    if (inputText.val.length == 0) {
      Utils.showSnackBar("Text can't be empty");
      return;
    }
    if (isUploading) {
      return;
    }

    const details = CreatePostDetails.create({
      title: inputText.val,
      replyingTo: post.id,
      postType: POSTTYPE.SAFE,
    });

    isUploading = true;
    const createdPost = await createPost(details);
    if (createdPost) {
      replies.push(
        vanX.noreactive(
          createdPost,
          // FullPost.create({
          //   by: (await AuthHandler.getUsername())!,
          //   title: inputText.val,
          //   replyingTo: post.id,
          //   id: ulidFromString(id),
          //   postType:
          //     (post.postType & POSTTYPE.ANONYMOUS) == POSTTYPE.ANONYMOUS
          //       ? post.postType ^ POSTTYPE.ANONYMOUS
          //       : post.postType,
          // }),
        ),
      );
    }
    isUploading = false;
    inputText.val = "";
  };

  const el = div(
    { class: "lupyd-post-replies" },
    div(
      { class: "row" },
      input({
        value: inputText,
        class: "post-reply-input theme-input",
        type: "text",
        onkeydown(e: KeyboardEvent) {
          if (e.key === "Enter") {
            uploadReply();
          }
        },
        oninput() {
          inputText.val = (this as HTMLInputElement).value;
        },
      }),
      button(
        { onclick: uploadReply, class: "no-button-decoration" },
        UiIcon("send"),
      ),
    ),

    vanX.list(ul({ class: "no-pad no-list-style" }), replies, (post) =>
      li(postElement(post.val, true, false, true)),
    ),
    () =>
      replies.length < repliesCount.val
        ? button(
            { onclick: loadMore, class: "theme-button" },
            "load more replies ...",
          )
        : div(),
  );

  setTimeout(() => el.querySelector("input")!.focus());

  return el;
}
